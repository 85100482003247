import { default as aboutyaGXplS88FMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsAlwvohFcdCMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93Eg1v1qk4p0Meta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleWL9VM8xrA9Meta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsVEs970sza5Meta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_934tm2DthImWMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewdkEL3JXsI6Meta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexniZYfKUvKnMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93aVuGFypTrFMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexgH1IXf3or2Meta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93qOnIwVHN3CMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as index8TODZ2ziu6Meta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsj60EpxzkqDMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsigzqYFIwEqMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsudHkbxH8SnMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_936JItXfIrqQMeta } from "/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexSLAiO2Vp2uMeta } from "/tmp/build_bd19370e/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmMR9CC5Rrp1Meta } from "/tmp/build_bd19370e/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmhk83QAdgAfMeta } from "/tmp/build_bd19370e/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendarYwERuTBVjaMeta } from "/tmp/build_bd19370e/pages/[studio_url]/calendar.vue?macro=true";
import { default as chatgUOTGTWFQSMeta } from "/tmp/build_bd19370e/pages/[studio_url]/chat.vue?macro=true";
import { default as about0Qk73Aw7hmMeta } from "/tmp/build_bd19370e/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedZRqVG7bjgLMeta } from "/tmp/build_bd19370e/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersngCErp2r5jMeta } from "/tmp/build_bd19370e/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_930wbYHwh0mvMeta } from "/tmp/build_bd19370e/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45editqLQAgK1XM9Meta } from "/tmp/build_bd19370e/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93HmNMNOHT9qMeta } from "/tmp/build_bd19370e/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as community9XuJ67nNGCMeta } from "/tmp/build_bd19370e/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93eRs5uo2KrTMeta } from "/tmp/build_bd19370e/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93A24CpeIIbxMeta } from "/tmp/build_bd19370e/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardE7FDn3jzZRMeta } from "/tmp/build_bd19370e/pages/[studio_url]/dashboard.vue?macro=true";
import { default as index2p7reHUvwDMeta } from "/tmp/build_bd19370e/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutYwiGKtglM0Meta } from "/tmp/build_bd19370e/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productMjY8GBQIOUMeta } from "/tmp/build_bd19370e/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsl9zrDboxGcMeta } from "/tmp/build_bd19370e/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93wbxgL6hPPfMeta } from "/tmp/build_bd19370e/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexdZ5aQFTnkGMeta } from "/tmp/build_bd19370e/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93j3w1doYyg8Meta } from "/tmp/build_bd19370e/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93JWLgFxZU8cMeta } from "/tmp/build_bd19370e/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexSz24YxoQrLMeta } from "/tmp/build_bd19370e/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93EUBm5FGd1ZMeta } from "/tmp/build_bd19370e/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinapd2RPXjLzMeta } from "/tmp/build_bd19370e/pages/[studio_url]/join.vue?macro=true";
import { default as logind2JtH2i2hRMeta } from "/tmp/build_bd19370e/pages/[studio_url]/login.vue?macro=true";
import { default as logoutMb9zGAUXSVMeta } from "/tmp/build_bd19370e/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_937U5Bn06SjMMeta } from "/tmp/build_bd19370e/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_939jWXEuY0rWMeta } from "/tmp/build_bd19370e/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_93vbQ2jUtr1LMeta } from "/tmp/build_bd19370e/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesqCmpGcYiQDMeta } from "/tmp/build_bd19370e/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosgVqsQUGhh3Meta } from "/tmp/build_bd19370e/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optin49o5E1x8YEMeta } from "/tmp/build_bd19370e/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_93GQMzoLXQDXMeta } from "/tmp/build_bd19370e/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as index1wifOTBA57Meta } from "/tmp/build_bd19370e/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexfLcPxOVdG7Meta } from "/tmp/build_bd19370e/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutSJfAxpfu8mMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as digital_45download_45contentfVwPh7AyxwMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/digital-download-content.vue?macro=true";
import { default as eventsQskzBScgP6Meta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93vMXz0cvFz5Meta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleSC6yltrMDYMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productstYpdxhoPBbMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93rb59UyN2ERMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewQWdj9IySAEMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as index50bY1VhsnKMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93f5gybG36l6Meta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as index3jLm8tY24HMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93wxNJLjsLnXMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as index0gr72pP9U4Meta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsdkVMwVyKB8Meta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsAeJ7pEJ0pBMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsPUUTzEnHngMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93KAjzTWvyeRMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexsI8XWbMGUOMeta } from "/tmp/build_bd19370e/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexBeLStcmm8bMeta } from "/tmp/build_bd19370e/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesCrkMoR8owTMeta } from "/tmp/build_bd19370e/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsgvaFfnPWO9Meta } from "/tmp/build_bd19370e/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93lbxC9tn351Meta } from "/tmp/build_bd19370e/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93GS6UIZjtMeMeta } from "/tmp/build_bd19370e/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resetqThELc9XrZMeta } from "/tmp/build_bd19370e/pages/[studio_url]/reset.vue?macro=true";
import { default as shopAJ4n6kbnjNMeta } from "/tmp/build_bd19370e/pages/[studio_url]/shop.vue?macro=true";
import { default as signupaRwV2PwIFRMeta } from "/tmp/build_bd19370e/pages/[studio_url]/signup.vue?macro=true";
import { default as liveADS3itfHwFMeta } from "/tmp/build_bd19370e/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipseWjnO9FaqrMeta } from "/tmp/build_bd19370e/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93zNWZWz9cz1Meta } from "/tmp/build_bd19370e/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactiOMAQrhgI5Meta } from "/tmp/build_bd19370e/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fullOsh3F4nSM9Meta } from "/tmp/build_bd19370e/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_936JItXfIrqQMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_936JItXfIrqQMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_936JItXfIrqQMeta || {},
    alias: _91product_id_936JItXfIrqQMeta?.alias || [],
    redirect: _91product_id_936JItXfIrqQMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutyaGXplS88FMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutyaGXplS88FMeta?.path ?? "about",
    meta: aboutyaGXplS88FMeta || {},
    alias: aboutyaGXplS88FMeta?.alias || [],
    redirect: aboutyaGXplS88FMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsAlwvohFcdCMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsAlwvohFcdCMeta?.path ?? "events",
    meta: eventsAlwvohFcdCMeta || {},
    alias: eventsAlwvohFcdCMeta?.alias || [],
    redirect: eventsAlwvohFcdCMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93Eg1v1qk4p0Meta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93Eg1v1qk4p0Meta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93Eg1v1qk4p0Meta || {},
    alias: _91media_id_93Eg1v1qk4p0Meta?.alias || [],
    redirect: _91media_id_93Eg1v1qk4p0Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleWL9VM8xrA9Meta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sampleWL9VM8xrA9Meta?.path ?? "free-sample",
    meta: free_45sampleWL9VM8xrA9Meta || {},
    alias: free_45sampleWL9VM8xrA9Meta?.alias || [],
    redirect: free_45sampleWL9VM8xrA9Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsVEs970sza5Meta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsVEs970sza5Meta?.path ?? "included-products",
    meta: included_45productsVEs970sza5Meta || {},
    alias: included_45productsVEs970sza5Meta?.alias || [],
    redirect: included_45productsVEs970sza5Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_934tm2DthImWMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_934tm2DthImWMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_934tm2DthImWMeta || {},
    alias: _91media_id_934tm2DthImWMeta?.alias || [],
    redirect: _91media_id_934tm2DthImWMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewdkEL3JXsI6Meta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: viewdkEL3JXsI6Meta?.path ?? "lessons/:lesson_id()/view",
    meta: viewdkEL3JXsI6Meta || {},
    alias: viewdkEL3JXsI6Meta?.alias || [],
    redirect: viewdkEL3JXsI6Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexniZYfKUvKnMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexniZYfKUvKnMeta?.path ?? "lessons",
    meta: indexniZYfKUvKnMeta || {},
    alias: indexniZYfKUvKnMeta?.alias || [],
    redirect: indexniZYfKUvKnMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93aVuGFypTrFMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93aVuGFypTrFMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93aVuGFypTrFMeta || {},
    alias: _91media_id_93aVuGFypTrFMeta?.alias || [],
    redirect: _91media_id_93aVuGFypTrFMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexgH1IXf3or2Meta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexgH1IXf3or2Meta?.path ?? "playlists/:playlist_id()",
    meta: indexgH1IXf3or2Meta || {},
    alias: indexgH1IXf3or2Meta?.alias || [],
    redirect: indexgH1IXf3or2Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93qOnIwVHN3CMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93qOnIwVHN3CMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93qOnIwVHN3CMeta || {},
    alias: _91media_id_93qOnIwVHN3CMeta?.alias || [],
    redirect: _91media_id_93qOnIwVHN3CMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index8TODZ2ziu6Meta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: index8TODZ2ziu6Meta?.path ?? "playlists",
    meta: index8TODZ2ziu6Meta || {},
    alias: index8TODZ2ziu6Meta?.alias || [],
    redirect: index8TODZ2ziu6Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsj60EpxzkqDMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsj60EpxzkqDMeta?.path ?? "private-sessions",
    meta: private_45sessionsj60EpxzkqDMeta || {},
    alias: private_45sessionsj60EpxzkqDMeta?.alias || [],
    redirect: private_45sessionsj60EpxzkqDMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsigzqYFIwEqMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordingsigzqYFIwEqMeta?.path ?? "recordings",
    meta: recordingsigzqYFIwEqMeta || {},
    alias: recordingsigzqYFIwEqMeta?.alias || [],
    redirect: recordingsigzqYFIwEqMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsudHkbxH8SnMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsudHkbxH8SnMeta?.path ?? "reviews",
    meta: reviewsudHkbxH8SnMeta || {},
    alias: reviewsudHkbxH8SnMeta?.alias || [],
    redirect: reviewsudHkbxH8SnMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexSLAiO2Vp2uMeta?.name ?? "buy-product-product_id",
    path: indexSLAiO2Vp2uMeta?.path ?? "/buy/product/:product_id()",
    meta: indexSLAiO2Vp2uMeta || {},
    alias: indexSLAiO2Vp2uMeta?.alias || [],
    redirect: indexSLAiO2Vp2uMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmMR9CC5Rrp1Meta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmMR9CC5Rrp1Meta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmMR9CC5Rrp1Meta || {},
    alias: confirmMR9CC5Rrp1Meta?.alias || [],
    redirect: confirmMR9CC5Rrp1Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmhk83QAdgAfMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmhk83QAdgAfMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmhk83QAdgAfMeta || {},
    alias: confirmhk83QAdgAfMeta?.alias || [],
    redirect: confirmhk83QAdgAfMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendarYwERuTBVjaMeta?.name ?? "calendar",
    path: calendarYwERuTBVjaMeta?.path ?? "/calendar",
    meta: calendarYwERuTBVjaMeta || {},
    alias: calendarYwERuTBVjaMeta?.alias || [],
    redirect: calendarYwERuTBVjaMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatgUOTGTWFQSMeta?.name ?? "chat",
    path: chatgUOTGTWFQSMeta?.path ?? "/chat",
    meta: chatgUOTGTWFQSMeta || {},
    alias: chatgUOTGTWFQSMeta?.alias || [],
    redirect: chatgUOTGTWFQSMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_930wbYHwh0mvMeta?.name ?? "community-groups-group_id",
    path: _91group_id_930wbYHwh0mvMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_930wbYHwh0mvMeta || {},
    alias: _91group_id_930wbYHwh0mvMeta?.alias || [],
    redirect: _91group_id_930wbYHwh0mvMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: about0Qk73Aw7hmMeta?.name ?? "community-groups-group_id-about",
    path: about0Qk73Aw7hmMeta?.path ?? "about",
    meta: about0Qk73Aw7hmMeta || {},
    alias: about0Qk73Aw7hmMeta?.alias || [],
    redirect: about0Qk73Aw7hmMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedZRqVG7bjgLMeta?.name ?? "community-groups-group_id-feed",
    path: feedZRqVG7bjgLMeta?.path ?? "feed",
    meta: feedZRqVG7bjgLMeta || {},
    alias: feedZRqVG7bjgLMeta?.alias || [],
    redirect: feedZRqVG7bjgLMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersngCErp2r5jMeta?.name ?? "community-groups-group_id-members",
    path: membersngCErp2r5jMeta?.path ?? "members",
    meta: membersngCErp2r5jMeta || {},
    alias: membersngCErp2r5jMeta?.alias || [],
    redirect: membersngCErp2r5jMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45editqLQAgK1XM9Meta?.name ?? "community-profile-edit",
    path: community_45profile_45editqLQAgK1XM9Meta?.path ?? "/community-profile-edit",
    meta: community_45profile_45editqLQAgK1XM9Meta || {},
    alias: community_45profile_45editqLQAgK1XM9Meta?.alias || [],
    redirect: community_45profile_45editqLQAgK1XM9Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HmNMNOHT9qMeta?.name ?? "community-profile-id",
    path: _91id_93HmNMNOHT9qMeta?.path ?? "/community-profile/:id()",
    meta: _91id_93HmNMNOHT9qMeta || {},
    alias: _91id_93HmNMNOHT9qMeta?.alias || [],
    redirect: _91id_93HmNMNOHT9qMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: community9XuJ67nNGCMeta?.name ?? "community",
    path: community9XuJ67nNGCMeta?.path ?? "/community",
    meta: community9XuJ67nNGCMeta || {},
    alias: community9XuJ67nNGCMeta?.alias || [],
    redirect: community9XuJ67nNGCMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93eRs5uo2KrTMeta?.name ?? "complete-registration-uuid",
    path: _91uuid_93eRs5uo2KrTMeta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93eRs5uo2KrTMeta || {},
    alias: _91uuid_93eRs5uo2KrTMeta?.alias || [],
    redirect: _91uuid_93eRs5uo2KrTMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93A24CpeIIbxMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93A24CpeIIbxMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93A24CpeIIbxMeta || {},
    alias: _91unique_id_93A24CpeIIbxMeta?.alias || [],
    redirect: _91unique_id_93A24CpeIIbxMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardE7FDn3jzZRMeta?.name ?? "dashboard",
    path: dashboardE7FDn3jzZRMeta?.path ?? "/dashboard",
    meta: dashboardE7FDn3jzZRMeta || {},
    alias: dashboardE7FDn3jzZRMeta?.alias || [],
    redirect: dashboardE7FDn3jzZRMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: index2p7reHUvwDMeta?.name ?? "event-details-event_id",
    path: index2p7reHUvwDMeta?.path ?? "/event/details/:event_id()",
    meta: index2p7reHUvwDMeta || {},
    alias: index2p7reHUvwDMeta?.alias || [],
    redirect: index2p7reHUvwDMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutYwiGKtglM0Meta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutYwiGKtglM0Meta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutYwiGKtglM0Meta || {},
    alias: checkoutYwiGKtglM0Meta?.alias || [],
    redirect: checkoutYwiGKtglM0Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productMjY8GBQIOUMeta?.name ?? "event-details-event_id-select-product",
    path: select_45productMjY8GBQIOUMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productMjY8GBQIOUMeta || {},
    alias: select_45productMjY8GBQIOUMeta?.alias || [],
    redirect: select_45productMjY8GBQIOUMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsl9zrDboxGcMeta?.name ?? "events",
    path: eventsl9zrDboxGcMeta?.path ?? "/events",
    meta: eventsl9zrDboxGcMeta || {},
    alias: eventsl9zrDboxGcMeta?.alias || [],
    redirect: eventsl9zrDboxGcMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93wbxgL6hPPfMeta?.name ?? "gifts-uuid",
    path: _91uuid_93wbxgL6hPPfMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93wbxgL6hPPfMeta || {},
    alias: _91uuid_93wbxgL6hPPfMeta?.alias || [],
    redirect: _91uuid_93wbxgL6hPPfMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexdZ5aQFTnkGMeta?.name ?? "gifts",
    path: indexdZ5aQFTnkGMeta?.path ?? "/gifts",
    meta: indexdZ5aQFTnkGMeta || {},
    alias: indexdZ5aQFTnkGMeta?.alias || [],
    redirect: indexdZ5aQFTnkGMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93j3w1doYyg8Meta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93j3w1doYyg8Meta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93j3w1doYyg8Meta || {},
    alias: _91invitation_id_93j3w1doYyg8Meta?.alias || [],
    redirect: _91invitation_id_93j3w1doYyg8Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93JWLgFxZU8cMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_93JWLgFxZU8cMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93JWLgFxZU8cMeta || {},
    alias: _91product_id_93JWLgFxZU8cMeta?.alias || [],
    redirect: _91product_id_93JWLgFxZU8cMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexSz24YxoQrLMeta?.name ?? "index",
    path: indexSz24YxoQrLMeta?.path ?? "/",
    meta: indexSz24YxoQrLMeta || {},
    alias: indexSz24YxoQrLMeta?.alias || [],
    redirect: indexSz24YxoQrLMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93EUBm5FGd1ZMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93EUBm5FGd1ZMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93EUBm5FGd1ZMeta || {},
    alias: _91invitation_unique_id_93EUBm5FGd1ZMeta?.alias || [],
    redirect: _91invitation_unique_id_93EUBm5FGd1ZMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinapd2RPXjLzMeta?.name ?? "join",
    path: joinapd2RPXjLzMeta?.path ?? "/join",
    meta: joinapd2RPXjLzMeta || {},
    alias: joinapd2RPXjLzMeta?.alias || [],
    redirect: joinapd2RPXjLzMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: logind2JtH2i2hRMeta?.name ?? "login",
    path: logind2JtH2i2hRMeta?.path ?? "/login",
    meta: logind2JtH2i2hRMeta || {},
    alias: logind2JtH2i2hRMeta?.alias || [],
    redirect: logind2JtH2i2hRMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutMb9zGAUXSVMeta?.name ?? "logout",
    path: logoutMb9zGAUXSVMeta?.path ?? "/logout",
    meta: logoutMb9zGAUXSVMeta || {},
    alias: logoutMb9zGAUXSVMeta?.alias || [],
    redirect: logoutMb9zGAUXSVMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_937U5Bn06SjMMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_937U5Bn06SjMMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_937U5Bn06SjMMeta || {},
    alias: _91meeting_type_slug_937U5Bn06SjMMeta?.alias || [],
    redirect: _91meeting_type_slug_937U5Bn06SjMMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_939jWXEuY0rWMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_939jWXEuY0rWMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_939jWXEuY0rWMeta || {},
    alias: _91meeting_unique_id_939jWXEuY0rWMeta?.alias || [],
    redirect: _91meeting_unique_id_939jWXEuY0rWMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93vbQ2jUtr1LMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_93vbQ2jUtr1LMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_93vbQ2jUtr1LMeta || {},
    alias: _91meeting_unique_id_93vbQ2jUtr1LMeta?.alias || [],
    redirect: _91meeting_unique_id_93vbQ2jUtr1LMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesqCmpGcYiQDMeta?.name ?? "my-favorites",
    path: my_45favoritesqCmpGcYiQDMeta?.path ?? "/my-favorites",
    meta: my_45favoritesqCmpGcYiQDMeta || {},
    alias: my_45favoritesqCmpGcYiQDMeta?.alias || [],
    redirect: my_45favoritesqCmpGcYiQDMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosgVqsQUGhh3Meta?.name ?? "my-studios",
    path: my_45studiosgVqsQUGhh3Meta?.path ?? "/my-studios",
    meta: my_45studiosgVqsQUGhh3Meta || {},
    alias: my_45studiosgVqsQUGhh3Meta?.alias || [],
    redirect: my_45studiosgVqsQUGhh3Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optin49o5E1x8YEMeta?.name ?? "optin",
    path: optin49o5E1x8YEMeta?.path ?? "/optin",
    meta: optin49o5E1x8YEMeta || {},
    alias: optin49o5E1x8YEMeta?.alias || [],
    redirect: optin49o5E1x8YEMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_93GQMzoLXQDXMeta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_93GQMzoLXQDXMeta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_93GQMzoLXQDXMeta || {},
    alias: _91episode_id_93GQMzoLXQDXMeta?.alias || [],
    redirect: _91episode_id_93GQMzoLXQDXMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: index1wifOTBA57Meta?.name ?? "podcasts-podcast_id",
    path: index1wifOTBA57Meta?.path ?? "/podcasts/:podcast_id()",
    meta: index1wifOTBA57Meta || {},
    alias: index1wifOTBA57Meta?.alias || [],
    redirect: index1wifOTBA57Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfLcPxOVdG7Meta?.name ?? "podcasts",
    path: indexfLcPxOVdG7Meta?.path ?? "/podcasts",
    meta: indexfLcPxOVdG7Meta || {},
    alias: indexfLcPxOVdG7Meta?.alias || [],
    redirect: indexfLcPxOVdG7Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93KAjzTWvyeRMeta?.name ?? "product-product_id",
    path: _91product_id_93KAjzTWvyeRMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93KAjzTWvyeRMeta || {},
    alias: _91product_id_93KAjzTWvyeRMeta?.alias || [],
    redirect: _91product_id_93KAjzTWvyeRMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutSJfAxpfu8mMeta?.name ?? "product-product_id-about",
    path: aboutSJfAxpfu8mMeta?.path ?? "about",
    meta: aboutSJfAxpfu8mMeta || {},
    alias: aboutSJfAxpfu8mMeta?.alias || [],
    redirect: aboutSJfAxpfu8mMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: digital_45download_45contentfVwPh7AyxwMeta?.name ?? "product-product_id-digital-download-content",
    path: digital_45download_45contentfVwPh7AyxwMeta?.path ?? "digital-download-content",
    meta: digital_45download_45contentfVwPh7AyxwMeta || {},
    alias: digital_45download_45contentfVwPh7AyxwMeta?.alias || [],
    redirect: digital_45download_45contentfVwPh7AyxwMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/digital-download-content.vue").then(m => m.default || m)
  },
  {
    name: eventsQskzBScgP6Meta?.name ?? "product-product_id-events",
    path: eventsQskzBScgP6Meta?.path ?? "events",
    meta: eventsQskzBScgP6Meta || {},
    alias: eventsQskzBScgP6Meta?.alias || [],
    redirect: eventsQskzBScgP6Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93vMXz0cvFz5Meta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93vMXz0cvFz5Meta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93vMXz0cvFz5Meta || {},
    alias: _91media_id_93vMXz0cvFz5Meta?.alias || [],
    redirect: _91media_id_93vMXz0cvFz5Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleSC6yltrMDYMeta?.name ?? "product-product_id-free-sample",
    path: free_45sampleSC6yltrMDYMeta?.path ?? "free-sample",
    meta: free_45sampleSC6yltrMDYMeta || {},
    alias: free_45sampleSC6yltrMDYMeta?.alias || [],
    redirect: free_45sampleSC6yltrMDYMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productstYpdxhoPBbMeta?.name ?? "product-product_id-included-products",
    path: included_45productstYpdxhoPBbMeta?.path ?? "included-products",
    meta: included_45productstYpdxhoPBbMeta || {},
    alias: included_45productstYpdxhoPBbMeta?.alias || [],
    redirect: included_45productstYpdxhoPBbMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93rb59UyN2ERMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93rb59UyN2ERMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93rb59UyN2ERMeta || {},
    alias: _91media_id_93rb59UyN2ERMeta?.alias || [],
    redirect: _91media_id_93rb59UyN2ERMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewQWdj9IySAEMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewQWdj9IySAEMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewQWdj9IySAEMeta || {},
    alias: viewQWdj9IySAEMeta?.alias || [],
    redirect: viewQWdj9IySAEMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: index50bY1VhsnKMeta?.name ?? "product-product_id-lessons",
    path: index50bY1VhsnKMeta?.path ?? "lessons",
    meta: index50bY1VhsnKMeta || {},
    alias: index50bY1VhsnKMeta?.alias || [],
    redirect: index50bY1VhsnKMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93f5gybG36l6Meta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93f5gybG36l6Meta?.path ?? "media/:media_id()",
    meta: _91media_id_93f5gybG36l6Meta || {},
    alias: _91media_id_93f5gybG36l6Meta?.alias || [],
    redirect: _91media_id_93f5gybG36l6Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index3jLm8tY24HMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: index3jLm8tY24HMeta?.path ?? "playlists/:playlist_id()",
    meta: index3jLm8tY24HMeta || {},
    alias: index3jLm8tY24HMeta?.alias || [],
    redirect: index3jLm8tY24HMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93wxNJLjsLnXMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93wxNJLjsLnXMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93wxNJLjsLnXMeta || {},
    alias: _91media_id_93wxNJLjsLnXMeta?.alias || [],
    redirect: _91media_id_93wxNJLjsLnXMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index0gr72pP9U4Meta?.name ?? "product-product_id-playlists",
    path: index0gr72pP9U4Meta?.path ?? "playlists",
    meta: index0gr72pP9U4Meta || {},
    alias: index0gr72pP9U4Meta?.alias || [],
    redirect: index0gr72pP9U4Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsdkVMwVyKB8Meta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsdkVMwVyKB8Meta?.path ?? "private-sessions",
    meta: private_45sessionsdkVMwVyKB8Meta || {},
    alias: private_45sessionsdkVMwVyKB8Meta?.alias || [],
    redirect: private_45sessionsdkVMwVyKB8Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsAeJ7pEJ0pBMeta?.name ?? "product-product_id-recordings",
    path: recordingsAeJ7pEJ0pBMeta?.path ?? "recordings",
    meta: recordingsAeJ7pEJ0pBMeta || {},
    alias: recordingsAeJ7pEJ0pBMeta?.alias || [],
    redirect: recordingsAeJ7pEJ0pBMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsPUUTzEnHngMeta?.name ?? "product-product_id-reviews",
    path: reviewsPUUTzEnHngMeta?.path ?? "reviews",
    meta: reviewsPUUTzEnHngMeta || {},
    alias: reviewsPUUTzEnHngMeta?.alias || [],
    redirect: reviewsPUUTzEnHngMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexsI8XWbMGUOMeta?.name ?? "product",
    path: indexsI8XWbMGUOMeta?.path ?? "/product",
    meta: indexsI8XWbMGUOMeta || {},
    alias: indexsI8XWbMGUOMeta?.alias || [],
    redirect: indexsI8XWbMGUOMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexBeLStcmm8bMeta?.name ?? "profile",
    path: indexBeLStcmm8bMeta?.path ?? "/profile",
    meta: indexBeLStcmm8bMeta || {},
    alias: indexBeLStcmm8bMeta?.alias || [],
    redirect: indexBeLStcmm8bMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesCrkMoR8owTMeta?.name ?? "profile-purchases",
    path: purchasesCrkMoR8owTMeta?.path ?? "/profile/purchases",
    meta: purchasesCrkMoR8owTMeta || {},
    alias: purchasesCrkMoR8owTMeta?.alias || [],
    redirect: purchasesCrkMoR8owTMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsgvaFfnPWO9Meta?.name ?? "profile-redemptions",
    path: redemptionsgvaFfnPWO9Meta?.path ?? "/profile/redemptions",
    meta: redemptionsgvaFfnPWO9Meta || {},
    alias: redemptionsgvaFfnPWO9Meta?.alias || [],
    redirect: redemptionsgvaFfnPWO9Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93lbxC9tn351Meta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93lbxC9tn351Meta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93lbxC9tn351Meta || {},
    alias: _91quiz_id_93lbxC9tn351Meta?.alias || [],
    redirect: _91quiz_id_93lbxC9tn351Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93GS6UIZjtMeMeta?.name ?? "reset-password-uid-token",
    path: _91token_93GS6UIZjtMeMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93GS6UIZjtMeMeta || {},
    alias: _91token_93GS6UIZjtMeMeta?.alias || [],
    redirect: _91token_93GS6UIZjtMeMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resetqThELc9XrZMeta?.name ?? "reset",
    path: resetqThELc9XrZMeta?.path ?? "/reset",
    meta: resetqThELc9XrZMeta || {},
    alias: resetqThELc9XrZMeta?.alias || [],
    redirect: resetqThELc9XrZMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopAJ4n6kbnjNMeta?.name ?? "shop",
    path: shopAJ4n6kbnjNMeta?.path ?? "/shop",
    meta: shopAJ4n6kbnjNMeta || {},
    alias: shopAJ4n6kbnjNMeta?.alias || [],
    redirect: shopAJ4n6kbnjNMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupaRwV2PwIFRMeta?.name ?? "signup",
    path: signupaRwV2PwIFRMeta?.path ?? "/signup",
    meta: signupaRwV2PwIFRMeta || {},
    alias: signupaRwV2PwIFRMeta?.alias || [],
    redirect: signupaRwV2PwIFRMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveADS3itfHwFMeta?.name ?? "streaming-event_id-live",
    path: liveADS3itfHwFMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveADS3itfHwFMeta || {},
    alias: liveADS3itfHwFMeta?.alias || [],
    redirect: liveADS3itfHwFMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipseWjnO9FaqrMeta?.name ?? "tips",
    path: tipseWjnO9FaqrMeta?.path ?? "/tips",
    meta: tipseWjnO9FaqrMeta || {},
    alias: tipseWjnO9FaqrMeta?.alias || [],
    redirect: tipseWjnO9FaqrMeta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93zNWZWz9cz1Meta?.name ?? "waiver-uuid",
    path: _91uuid_93zNWZWz9cz1Meta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93zNWZWz9cz1Meta || {},
    alias: _91uuid_93zNWZWz9cz1Meta?.alias || [],
    redirect: _91uuid_93zNWZWz9cz1Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactiOMAQrhgI5Meta?.name ?? "widgets-calendars-compact",
    path: compactiOMAQrhgI5Meta?.path ?? "/widgets/calendars/compact",
    meta: compactiOMAQrhgI5Meta || {},
    alias: compactiOMAQrhgI5Meta?.alias || [],
    redirect: compactiOMAQrhgI5Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fullOsh3F4nSM9Meta?.name ?? "widgets-calendars-full",
    path: fullOsh3F4nSM9Meta?.path ?? "/widgets/calendars/full",
    meta: fullOsh3F4nSM9Meta || {},
    alias: fullOsh3F4nSM9Meta?.alias || [],
    redirect: fullOsh3F4nSM9Meta?.redirect,
    component: () => import("/tmp/build_bd19370e/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]